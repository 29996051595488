import React from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import i18n from "../../helpers/i18n";

const LanguageSelector = () => {
  const [selLan, setSelLan] = React.useState(i18n.language);
  const { t } = useTranslation();

  return (
    <>
      <a
        className="btn btn-sm btn-outline-primary btn-icon ml-3 px-3 py-2 rounded-pill"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span className="btn-inner--icon">
          <Icon.Globe size="16" />
        </span>{" "}
        <span>
          {selLan === "en"
            ? "EN"
            : selLan === "es"
            ? "ES"
            : selLan === "fr"
            ? "FR"
            : selLan === "de"
            ? "DE"
            : selLan === "pt"
            ? "PT"
            : selLan === "hi"
            ? "HI"
            : selLan === "it"
            ? "IT"
            : selLan === "ja"
            ? "JA"
            : navigator.language.substring(0, 2).toUpperCase() ||
              navigator.userLanguage.substring(0, 2).toUpperCase()}
        </span>
      </a>
      <div
        className="dropdown-menu dropdown-menu-sm p-0 mt-1 Karla"
        style={{ right: "0", margin: "2rem" }}
      >
        <ul className="list-group list-group-flush px-lg-4">
          <li
            className="dropdown dropdown-animate dropdown-submenu radius-none"
            data-toggle="hover"
          >
            <button
              className="list-group-item list-group-item-action radius-none px-3"
              onClick={() => {
                setSelLan("es");
                i18n.changeLanguage("es");
              }}
            >
              Español
            </button>
          </li>
          <li
            className="dropdown dropdown-animate dropdown-submenu radius-none"
            data-toggle="hover"
          >
            <button
              className="list-group-item list-group-item-action radius-none px-3"
              onClick={() => {
                setSelLan("en");
                i18n.changeLanguage("en");
              }}
            >
              English
            </button>
          </li>
          <li
            className="dropdown dropdown-animate dropdown-submenu radius-none"
            data-toggle="hover"
          >
            <button
              className="list-group-item list-group-item-action radius-none px-3"
              onClick={() => {
                setSelLan("fr");
                i18n.changeLanguage("fr");
              }}
            >
              Français
            </button>
          </li>
          <li
            className="dropdown dropdown-animate dropdown-submenu radius-none"
            data-toggle="hover"
          >
            <button
              className="list-group-item list-group-item-action radius-none px-3"
              onClick={() => {
                setSelLan("de");
                i18n.changeLanguage("de");
              }}
            >
              Deutsch
            </button>
          </li>
          <li
            className="dropdown dropdown-animate dropdown-submenu radius-none"
            data-toggle="hover"
          >
            <button
              className="list-group-item list-group-item-action radius-none px-3"
              onClick={() => {
                setSelLan("pt");
                i18n.changeLanguage("pt");
              }}
            >
              Português
            </button>
          </li>
          <li
            className="dropdown dropdown-animate dropdown-submenu radius-none"
            data-toggle="hover"
          >
            <button
              className="list-group-item list-group-item-action radius-none px-3"
              onClick={() => {
                setSelLan("it");
                i18n.changeLanguage("it");
              }}
            >
              Italiano
            </button>
          </li>
          <li
            className="dropdown dropdown-animate dropdown-submenu radius-none"
            data-toggle="hover"
          >
            <button
              className="list-group-item list-group-item-action radius-none px-3"
              onClick={() => {
                setSelLan("ja");
                i18n.changeLanguage("ja");
              }}
            >
              日本語
            </button>
          </li>
          <li
            className="dropdown dropdown-animate dropdown-submenu radius-none"
            data-toggle="hover"
          >
            <button
              className="list-group-item list-group-item-action radius-none px-3"
              onClick={() => {
                setSelLan("hi");
                i18n.changeLanguage("hi");
              }}
            >
              हिंदी
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LanguageSelector;
