import i18n from "i18next";
import axios from "axios";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/translation-en.json";
import translationES from "./locales/translation-es.json";
import translationFR from "./locales/translation-fr.json";
import translationHI from "./locales/translation-hi.json";
import translationPT from "./locales/translation-pt.json";
import translationDE from "./locales/translation-de.json";
import translationIT from "./locales/translation-it.json";
import translationJA from "./locales/translation-ja.json";
import config from "config";

const init_i18n = (config) => {
  i18n.use(detector).use(initReactI18next).init(config);
};

const defaultConfig = {
  resources: {
    es: {
      translation: translationES,
    },
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
    hi: {
      translation: translationHI,
    },
    de: {
      translation: translationDE,
    },
    pt: {
      translation: translationPT,
    },
    it: {
      translation: translationIT,
    },
    ja: {
      translation: translationJA,
    },
  },
  //   lng: "es",
  fallbackLng: "es",
  debug: true,
  returnObjects: true,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
};

const configFromServer = {
  resources: {
    es: {
      translation: translationES,
    },
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
    hi: {
      translation: translationHI,
    },
    de: {
      translation: translationDE,
    },
    pt: {
      translation: translationPT,
    },
    it: {
      translation: translationIT,
    },
    ja: {
      translation: translationJA,
    },
  },
  fallbackLng: "es",
  debug: true,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
};

const fetchTranslation = (fileName) =>
  axios
    .get(
      `https://${config.STORAGE}.blob.core.windows.net/docs/${fileName}.json`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    .then((res) => {
      return res.data;
    });

const initialize = () => {
  init_i18n(defaultConfig);
  setTimeout(async () => {
    const [
      data_en,
      data_es,
      data_fr,
      data_hi,
      data_pt,
      data_de,
      data_it,
      data_ja,
    ] = await Promise.all([
      fetchTranslation("translation-en"),
      fetchTranslation("translation-es"),
      fetchTranslation("translation-fr"),
      fetchTranslation("translation-hi"),
      fetchTranslation("translation-pt"),
      fetchTranslation("translation-de"),
      fetchTranslation("translation-it"),
      fetchTranslation("translation-ja"),
    ]);
    configFromServer.resources.en["translation"] = data_en;
    configFromServer.resources.es["translation"] = data_es;
    configFromServer.resources.fr["translation"] = data_fr;
    configFromServer.resources.hi["translation"] = data_hi;
    configFromServer.resources.pt["translation"] = data_pt;
    configFromServer.resources.de["translation"] = data_de;
    configFromServer.resources.it["translation"] = data_it;
    configFromServer.resources.ja["translation"] = data_ja;
    init_i18n(configFromServer);
  }, 1000);
};

initialize();

export default i18n;
